import CommonService from 'fairstatcore/services/Common';

export default class SellersService extends CommonService {
  async fetchDetail(id) {
    try {
      const res = await this.client.get(`/${id}`);
      return res.data;
    } catch (err) {
      console.error('Sellers error:', err);
      throw err;
    }
  }
}
