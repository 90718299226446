import CommonService from 'fairstatcore/services/Common';

export default class ReportsService extends CommonService {
  async fetchMarketplaces() {
    try {
      const res = await this.client.get('/dict/marketplaces');
      return res.data;
    } catch (err) {
      console.error('ReportsService error:', err);
      throw err;
    }
  }

  async fetchSales() {
    try {
      const res = await this.client.get('/dict/sales');
      return res.data;
    } catch (err) {
      console.error('ReportsService error:', err);
      throw err;
    }
  }
}
