export default [
  {
    path: "/product-analytics",
    component: () => import("@/pages/ProductAnalytics/Index.vue"),
    name: "ProductAnalyticsIndex",
    meta: {
      title: 'Товарная аналитик',
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=int`,
      checkAvailableWithTariff: ['OUT_WB', 'OUT_OZ'], //'IN_WB', 'IN_OZ'
    },
    redirect: { name: 'ProductAnalyticsTurnoverOfGoods' },
    children: [
      {
        path: "turnover-of-goods",
        component: () => import("@/pages/ProductAnalytics/TurnoverOfGoods.vue"),
        name: "ProductAnalyticsTurnoverOfGoods",
        meta: {title: 'Оборачиваемость товаров'},
      },
      {
        path: "deliveries-goods",
        component: () => import("@/pages/ProductAnalytics/DeliveriesGoods.vue"),
        name: "ProductAnalyticsDeliveriesOfGoods",
        meta: {title: 'Поставки товаров на склады'},
      }
    ],
  }
]
