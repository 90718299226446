import { accountsService } from "fairstatcore/services";

/**
 * Проверяем доступ к разделу с отчетами в зависимости от тарифа
 * @param tariffCode{number} - Код тарифа из meta router'a
 * @param currentTariff{null|object} - Текущий тариф пользователя
 * @param customerId{number} - ID организации
 * @param customerType{string} - Type организации
 * @param currentUser{null|object}
 * @param href{string} - $route.href || из хука to.href
 * @param isis{boolean} - разрешение к внут аналитике на уровне прав пользователя
 * @returns {Promise<number>} / 0 - доступ запрещен / 1 - доступ разрещен / 2 - требует подтверждения телефона
 */
export const checkAccessToReports = async (
  tariffCode,
  currentTariff,
  customerId,
  customerType,
  currentUser,
  href,
  isis,
  tariffHasExpired = false,
) => {
  if (!currentTariff && !tariffCode) return 1;

  if (tariffHasExpired) return 0; // Запрещено если срок тарифа истек
  try {
    const modules = await accountsService.fetch({
      path: `/tarifs/${currentTariff.tarif_id}/modules`
    })

    // Разрешаем доступ только для демо организации || по модулю из тарифа
    if (
      (modules && modules.findIndex(({ code }) => (tariffCode || []).some((it) => it.toLowerCase() === code.toLowerCase())) !== -1) &&
      !isis
    ) {
      // Для просмотра отчетов - нужно подтверждение телефона
      if (
        href?.indexOf('/report/') !== -1 &&
        currentUser && currentUser.status !== 4 && !currentUser?.phone_confirmed
      ) return 2; // требует подтверждение телефона
      return 1;
    }
    return 0;
  } catch (err) {
    return Number(!(customerId && customerId !== 'null'));
  }
}
