<template lang="pug">
  metainfo
    template(v-slot:title="{ content }") {{ content ? `${content} / FairStat — Портал аналитический с данными маркетплейсов` : 'FairStat — Портал аналитический с данными маркетплейсов' }}
  v-app
    .wrapper(v-if="!['SignIn', 'SignUp', 'RecoveryPassword', 'ConfirmRegistration', 'ChangePassword', 'UnavailableWithPing', 'UnavailableWithMaintenance', 'SuggestToken', 'Unsubscribe', 'PluginComeback', 'PluginInstall'].includes($route.name) && !appIsLoading && !!currentUser")
      ReleaseNotesDialog

      TheLeftMenuCompact
        TheLeftMenuList(is-mini, @onImport="(settings) => importSettings = settings", @onImportUpload="onImportUpload")
      TheLeftMenuFull(v-model="openedMenus.mainNav")
        TheLeftMenuList(@onImport="(settings) => importSettings = settings", @onImportUpload="onImportUpload")

      TheHeader(
        :attach="true"
        :drawer-is-visible="openedMenus.mainNav"
        :right-menu="openedMenus.rightMenu"
        :customers="customers"
        @click-menu-icon="onClickBurger"
        @click-right-menu-switcher="onClickRightMenuSwitcher"
      )

      TheRightMenu(
        :type="openedMenus.rightMenu"
        @update:modelValue="onClickCloseMenu"
      )

      v-progress-circular(
        v-if="appIsLoading"
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ).app__progress

      v-main(
        :class=`{
          'app__main--with-aside': $vuetify.display.mdAndUp && asideIsVisible,
          '-dark': $route.name === 'Dashboard',
          'menu-is-open': openedMenus.mainNav && $vuetify.display.lgAndUp
      }`
      ).app__main
        Vue3Tutorial(
          ref="tutorial"
          v-if="interactiveInstruction && !appIsLoading && instructionIsRunning && !$vuetify.display.mdAndDown && (currentUser && currentUser.status !== 4)"
          :tutorial="interactiveInstruction"
          :options="instructionOptions"
          :open="true"
          @stop="instructionIsRunning = false"
        )
        firebase-analytics
        .app__main-view.pr-md-6.pl-md-6.pr-2.pl-2
          router-view
    AuthView(v-else-if="['SignIn', 'SignUp', 'RecoveryPassword', 'ConfirmRegistration', 'ChangePassword'].includes($route.name) && !appIsLoading")
    router-view(v-else)
    AppImport(:importSettings="importSettings")
    TheNotificator
</template>

<script>
import { defineAsyncComponent } from 'vue'

import NProgress from "nprogress";
import { apiKeysService, organizationService, importService } from "./services";
import keysMp from "./mocks/keysMp.json";
import { debounce } from "lodash-es";
import eventBus from "fairstatcore/eventBus";
import { accountsService } from "fairstatcore/services";
import {mapActions, mapMutations, mapState} from 'vuex';
import AuthView from "./pages/Auth/Index.vue";
import TheLeftMenuCompact from "fairstatcore/components/Layout/TheLeftMenuCompact.vue";
import TheLeftMenuFull from "fairstatcore/components/Layout/TheLeftMenuFull.vue";
import TheLeftMenuList from "fairstatcore/components/Layout/TheLeftMenuList.vue";
import TheHeader from "fairstatcore/components/Layout/Header/TheHeader.vue";
import TheRightMenu from "fairstatcore/components/Layout/RightMenu/RightMenu.vue";
import authService from 'fairstatcore/services/Auth';
import operation from "fairstatcore/mixins/operationClients";
import { checkAccessToReports } from "./utils/index.js";
import ReleaseNotesDialog from "./components/ReleaseNotesDialog.vue";

export default {
  mixins: [operation],
  components: {
    AppImport: defineAsyncComponent(() =>
        import('fairstatcore/components/AppImport.vue')
    ),
    TheLeftMenuCompact,
    TheLeftMenuFull,
    TheLeftMenuList,
    TheHeader,
    TheRightMenu,
    Vue3Tutorial: defineAsyncComponent(() =>
        import('vue3-tutorial')
    ),
    AuthView,
    ReleaseNotesDialog,
  },
  data() {
    return {
      getCurrentClientErrors: 0,
      importSettings: null,
      routes: null,
      openedMenus: {
        mainNav: false,
        rightMenu: null,
      },
      appIsLoading: true,
      customers: [],
    };
  },
  computed: {
    ...mapState([
      'asideIsVisible',
    ]),
    ...mapState('accounts', [
      'currentUser',
    ]),
    ...mapState('organization', ['customerId', 'customerType', 'currentClient']),
    instructionIsRunning: {
      set(val) {
        localStorage.setItem('tutorial', val);
        this.setItem({
          item: 'instructionIsRunning',
          value: val,
        });
        this.$router.push({ name: 'Dashboard' }).catch(() => {});
      },
      get() {
        return this.$store.state.instructionIsRunning;
      },
    },
    instructionOptions() {
      return {
        // debug: true,
        texts: {
          stepState: 'Шаг %(currentStep)s из %(totalStep)s',
          nextButton: 'Далее',
          finishButton: 'Завершить',
          previousButton: 'Назад',
          skipButtonTitle: 'Пропустить обучение',
          skipConfirm: 'Вы хотите завершить обучение?'
        }
      };
    },
    interactiveInstruction() {
      return {
        name: "Getting started",
        steps: [
          {
            target: "",
            title: "Добро пожаловать в FairStat",
            content: "Это короткое руководство покажет Вам как работать с нашим сервисом.\n\n>:info:_Вы можете пропустить его в любое время, нажав на кнопку **×** в верхней части этого окна или нажать **`escape`**._",
            texts: {
              nextButton: 'Начать'
            }
          },
          // Инструкция по шапке сайта
          {
            target: ".the-header",
            title: "Шапка сайта",
            content: "В шапке сайта находится быстрый поиск товара, выбор организации и профиль пользователя.",
          },
          {
            target: ".the-header__search",
            title: "Быстрый поиск товара",
            content: "Вы можете искать товары по названию категорий, брендов, продавцов или по артикулу.\n\nКроме того, можете воспользоваться историей Вашего поиска.",
          },
          {
            target: ".the-header__organization",
            title: "Выбор организации",
            content: "Здесь отображается выбранная организация, её тариф и дата истечения срока тарифа",
            options: {
              position: 'auto',
            },
            nextStep: () => {
              document.querySelector('.the-header__organization').dispatchEvent(new Event('click'));
              return '+1';
            }
          },
          {
            target: ".the-header__organization-sticky .v-input",
            title: "Список организаций",
            content: "Можно отфильтровать организации по названию или идентификатору",
            options: {
              position: 'auto',
            },
            previousStep: () => {
              document.querySelector('.the-header__organization').dispatchEvent(new Event('click'));
              return '-1';
            }
          },
          {
            target: ".the-header__organization-sticky .v-btn",
            title: "Список организаций",
            content: "Перейти к созданию организации",
            options: {
              position: 'left',
            },
          },
          {
            target: ".the-header__organization-list.v-list",
            title: "Список организаций",
            content: "Или выбрать из имеющихся",
            options: {
              position: 'left',
            },
            nextStep: () => {
              document.querySelector('.the-header__organization').dispatchEvent(new Event('click'));
              return '+1';
            }
          },
          {
            target: ".the-header__account",
            title: "Профиль",
            content: "Это кнопка отвечает за показ информации о вашем аккаунте и текущей организации",
            options: {
              position: 'left',
            },
            previousStep: () => {
              document.querySelector('.the-header__organization').dispatchEvent(new Event('click'));
              return '-1';
            },
            nextStep: () => {
              document.querySelector('.the-header__account').dispatchEvent(new Event('click'));
              return '+1';
            }
          },
          {
            target: ".the-right-menu .account__group:first-child",
            title: "Профиль",
            content: "В первой группе отображается Ваше имя, Email адрес, указанный при регистрации и номер телефона.\nТакже Вы можете перейти в профиль для его редактирования или завершить сеанс",
            options: {
              position: 'left',
            },
            previousStep: () => {
              document.querySelector('.the-header__account').dispatchEvent(new Event('click'));
              return '-1';
            },
          },
          {
            target: ".the-right-menu .account__group:last-child",
            title: "Профиль",
            content: "Во второй группе отображается общая информация о текущей организации",
            options: {
              position: 'left',
            },
          },
          {
            target: ".the-right-menu .account__group:last-child .account__actions",
            title: "Профиль",
            content: "Для просмотра всей информации и управления организацией Вы можете нажать на кнопку **Управление организацией**",
            options: {
              position: 'left',
            },
            nextStep: () => {
              document.querySelector('.the-header__burger').dispatchEvent(new Event('click'));
              return '+1';
            }
          },
          // Инструкция по основной навигации
          {
            target: ".the-left-menu-full .app-navigation__wrapper",
            title: "Навигационное меню",
            content: "Для быстрого доступа к разделам Вы можете использовать навигационное меню.",
            options: {
              position: 'right',
            },
            previousStep: () => {
              document.querySelector('.the-header__account').dispatchEvent(new Event('click'));
              return '-1';
            }
          },
          {
            target: ".the-left-menu-full .app-navigation__wrapper",
            title: "Навигационное меню",
            content: "Для поиска и просмотра всех **Брендов** и **Продавцов** Вы можете перейти в разделы которые выделены на экране синим цветом.\n\n>:info:_Разделы содержат данные по FBO/FBS продажам и выручке за 30 дней._",
            options: {
              position: 'right',
              highlight: ['a[href^="/search/brand"]', 'a[href^="/search/seller"]']
            },
          },
          {
            target: ".the-left-menu-full .app-navigation__wrapper",
            title: "Навигационное меню",
            content: "В данном разделе представлены Топ-100 товаров по FBO/FBS продажам и выручке за 30 дней.",
            options: {
              position: 'right',
              highlight: ['a[href^="/search/sku"]']
            },
          },
          {
            target: ".the-left-menu-full .app-navigation__wrapper",
            title: "Навигационное меню",
            content: "Вы можете перейти в разделы которые выделены у Вас на экране синим цветом для просмотра Внутренней аналитики.\n\nПосле перехода автоматически откроется самый первый отчет **Заказы/Остатки**.\n\n>:info:_Для работы с отчетами необходимо ввести ключи маркетплейса._",
            options: {
              position: 'right',
              highlight: ['a[href^="/report"]']
            },
            nextStep: async () => {
              document.querySelector('.the-header__burger').dispatchEvent(new Event('click'));

              const result = await checkAccessToReports(
                  ['IN_WB', 'IN_OZ'],
                  this.currentTariff,
                  this.customerId,
                  this.customerType,
                  this.currentUser,
                  '/report/', //fake url
                  this.operation && !this.operation['isis'],
                  Boolean(this.currentClient?.tariffHasExpired)
              )

              if (result === 1) {
                await this.$router.push('/report').catch(() => {});
                return '+1';
              }
              await this.beforeNextStepNeedChangeClient();
              return '+7';
            }
          },
          // Инструкция по отчетам
          {
            target: '',
            title: 'Отчеты',
            content: "В данном разделе представлены 4 отчета: Заказы/Остатки, Динамика, По недельно и По дням.",
            options: { scroll: false },
          },
          {
            target: '.table-more-filters__items',
            title: 'Настройка фильтров',
            content: "Для работы с отчетами **Внутренней аналитики** система предлагает ряд фильтров, которые позволяют настроить отчет для решения вашей задачи.\n\n>:info:_Набор значений в каждом фильтре может изменяться в зависимости от вида Отчета и действующего платного Тарифа._",
            options: {
              scroll: false,
            },
            nextStep: async () => {
              document.querySelector('.table-more-filters__items .table-header-date .v-input').dispatchEvent(new Event('click'));
              await new Promise((resolve) => setTimeout(() => resolve(), 500));
              return '+1';
            }
          },
          {
            target: '.table-more-filters__items .table-header-date .v-overlay__content',
            title: 'Настройка фильтров',
            content: "Данный фильтр ограничивает выборку данных по установленному диапазону Дат или позволяет установить произвольный диапазон Дат, в том числе и один день.",
            options: {
              scroll: false,
            },
            nextStep: async () => {
              document.querySelector('.table-more-filters__items .table-header-date .v-input').dispatchEvent(new Event('click'));
              return '+1';
            }
          },
          {
            target: '.table-more-filters__main-item[data-field="slice"]',
            title: 'Настройка фильтров',
            content: "Фильтр **Разрез данных** позволяет сгруппировать выборку данных по **Группам** и **Брендам Организации**-продавца, а так же вывести весь **Список товаров Организации**-продавца, по которым произошли изменения в отчетный Период.",
            options: {
              scroll: false,
            },
          },
          {
            target: '.table-more-filters__main-item[data-field="sale"]',
            title: 'Настройка фильтров',
            content: "Фильтр Склады ограничивает выборку данных по типу склада, с которого отгружены заказы.",
            options: {
              scroll: false,
            },
          },
          {
            target: '.table-more-filters__main-item[data-field="mp"]',
            title: 'Настройка фильтров',
            content: "Фильтр Маркетплейсы ограничивает выборку данных по одному или нескольким МП.",
            options: {
              scroll: false,
            },
            nextStep: this.beforeNextStepNeedChangeClient,
          },
          // Базова инструкция по организации
          ...(this.customerId !== null ? [
            {
              target: '.app-header__top',
              title: 'Управление организацией',
              content: 'В шапке страницы в которую Вы перешли для настройки отображается Название организации, ИНН и Статус.',
              previousStep: async () => {
                const result = await checkAccessToReports(
                    ['IN_WB', 'IN_OZ'],
                    this.currentTariff,
                    this.customerId,
                    this.customerType,
                    this.currentUser,
                    '/report/', //fake url
                    this.operation && !this.operation['isis'],
                    Boolean(this.currentClient?.tariffHasExpired)
                )

                if (result === 1) {
                  await this.$router.push({name: 'ReportSells'});
                  return '-1';
                }
                return '-7';
              },
            },
            {
              target: '.payments-view__cards .v-card:first-child',
              title: 'Управление организацией',
              content: 'В данном разделе отображается Текущий баланс с возможностью его Пополнения и просмотра Истории операций',
            },
            {
              target: '.payments-view__cards .v-card:last-child',
              title: 'Управление организацией',
              content: 'Текущий тариф с его сроком действия, кол-вом доступных для приглашения пользователей в данную организацию и смены текущего тарифа.',
            },
            {
              target: '.payments-view__history',
              title: 'Управление организацией',
              content: 'Так же, отображаются 5 последних операций',
              nextStep: async () => {
                if (!document.querySelector('.app-header__tabs .app-tabulator') || document.querySelector('.app-header__tabs .app-tabulator')?.style?.display === 'none') return '+3';

                NProgress.start();
                const formatKeys = await apiKeysService.fetchFormatKeys()
                await this.$router.push({ name: 'ClientsDetailsMarketplace' }).finally(() => {
                  const watcher = this.$watch(
                      () => this.$store.state.tabulatorDataLoading,
                      (val) => {
                        if (val) return;

                        const tabulator = this.$store.state.tabulator;
                        if (tabulator && this.currentClient?.type_str_id === 'outter_demo') {
                          tabulator.setData(keysMp.map(item => {
                            const { name, one_key } = formatKeys?.find(({ id }) => id === item?.mp_id);
                            return {
                              ...item,
                              mp_name: name,
                              auth_data: {
                                ...Object.values(item?.auth_data || {})?.reduce((prev, cur) => Object.values(cur).length ? Object.assign(prev, { [cur.caption || '']: cur.value }) : prev, {}),
                                ...(!one_key ? {
                                  'Склад': item?.sale_name,
                                } : {}),
                              }
                            }
                          }));
                        }

                        //hack для переопределения обводки таблицы, т.к. данные приезжаю позже чем выставляются стили
                        setTimeout(() => window.dispatchEvent(new Event('resize')));

                        if (watcher) watcher();
                      },
                      { immediate: true }
                  )
                });
                NProgress.done();
                return '+1';
              },
            },
            {
              target: ['.table', '.app-header__tabs'],
              title: 'Управление организацией',
              ...(this.currentClient?.type_str_id === 'outter_demo' ? {
                content: 'В разделе **Маркетплейсы** можно добавить/удалить ключ. Посмотреть ранее добавленные ключи и статусы по ним.\n\n>:info:_На данный момент в таблице отображается Демо ключ предназначенный только для Демонстрации данной вкладки!_',
              } : {
                content: 'В разделе **Маркетплейсы** можно добавить/удалить ключ. Посмотреть ранее добавленные ключи и статусы по ним.',
              }),
              previousStep: async () => {
                await this.$router.push({ name: 'ClientsDetailsPayment' }).catch(() => {});
                return '-1';
              },
              nextStep: async () => {
                await this.$router.push({ name: 'ClientsDetailMembers' }).finally(() => {
                  const watcher = this.$watch(
                      () => this.$store.state.tabulatorDataLoading,
                      (val) => {
                        if (val) return;

                        //hack для переопределения обводки таблицы, т.к. данные приезжаю позже чем выставляются стили
                        setTimeout(() => window.dispatchEvent(new Event('resize')));

                        if (watcher) watcher();
                      },
                      { immediate: true }
                  )
                }).catch(() => {});
                return '+1';
              },
            },
            {
              target: ['.table', '.app-header__tabs'],
              title: 'Управление организацией',
              content: 'В разделе **Пользователи** отображается список всех участников организации. Вы можете пригласить нового пользователя, Исключить/Заблокировать имеющегося пользователя или изменить его разрешения в рамках выбранной организации',
              previousStep: async () => {
                return '-2';
              },
            },
          ] : []),
          {
            target: '',
            content: '_Спасибо за внимание_'
          },
        ]
      }
    },
  },
  async created() {
    console.log('%cВНИМАНИЕ!', 'background: yellow; color: red; font-size: 24px;');
    console.log('%cИспользуя эту консоль, вы можете подвергнуться атаке Self-XSS, что позволит злоумышленникам совершать действия от вашего имени и получать доступ к вашим данным.\r\nНе вводите и не вставляйте программный код, который не понимаете.', 'font-size: 18px;');

    this.$watch(
        () => this.customerId,
        debounce(async (customerId = null, oldCustomerId = null) => {
          await this.autoSetCustomer(customerId, oldCustomerId);
        }, 100),
        { immediate: true, deep: true }
    )
    this.$watch(
        () => [this.currentClient, this.currentUser],
        ([client, user], [oldClient, oldUser]) => {
          if (!client || !user) return;
          if (+client.id === +oldClient?.id && +user?.id === +oldUser?.id) return;
          if (client.id && client.id !== "null") {
            if (this.$store.getters['organization/getCurrentTariff'] === null) this.$store.dispatch('organization/loadCurrentTariff').then((result) => this.$store.commit('organization/setCurrentTariff', result));

            this.fetchClientUser(user?.id).catch(() => {});

            if (import.meta.env.VITE_APP_MAILING_BOT)
              this.fetchMailingList(user?.login).catch(() => {});
          }
        },
        { deep: true }
    )
    this.$watch(
        () => this.$route,
        async (to, from) => {
          if (to?.params?.mp && to?.params?.mp !== localStorage.getItem('currentMarketplace')) {
            localStorage.setItem('currentMarketplace', to?.params?.mp);
            this.$store.commit('setItem', { item: 'currentMarketplace', value: to?.params?.mp });
          }

          if (to.name === 'UnavailableWithMaintenance') {
            this.appIsLoading = false;
          }
        },
        { immediate: true }
    )

    try {
      await this.onChangeAuthUser();
    } catch (err) {
      this.m_handleError(err);
      this.$router.push('/').catch(() => {});
    } finally {
      this.appIsLoading = false;
    }

    eventBus.$on('authorized', this.onChangeAuthUser);
    eventBus.$on('clickLogOut', this.onClickLogOut);
    eventBus.$on('createdOrganization', this.onUpdateCustomersList)
  },
  beforeUnmount() {
    eventBus.$off('authorized', this.onChangeAuthUser);
    eventBus.$off('clickLogOut', this.onClickLogOut);
    eventBus.$off('createdOrganization', this.onUpdateCustomersList)
  },
  methods: {
    ...mapActions('organization', ['fetchClientUser', 'currentTariff']),
    ...mapActions('mailing', ['fetchMailingList']),
    ...mapMutations([
      'setItem',
    ]),
    ...mapMutations('accounts', [
      'setCurrentUser',
    ]),
    ...mapActions('accounts', [
      'fetchCurrentUser',
    ]),
    ...mapMutations('organization', ['setCustomerId', 'setCustomerType', 'setCurrentClient']),
    async autoSetCustomer(customerId, oldCustomerId) {
      const currentUser = localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')) : null;
      if (currentUser === null) return;

      this.customers = this.customers && this.customers.length ? this.customers : await organizationService.fetchCustomers();

      if ((!customerId || customerId === 'null') && (!oldCustomerId || oldCustomerId === 'null')) {
        const { id = null, type_str_id = null } = this.customers?.find(({ type_str_id }) => type_str_id.toLowerCase().indexOf('outter_demo') !== -1) || (this.customers[0] || {});
        if (id === null) return
        localStorage.setItem('customerId', id);
        localStorage.setItem('customerType', type_str_id);
        customerId = id;

        this.$store.dispatch('organization/loadCurrentTariff').then((result) => this.$store.commit('organization/setCurrentTariff', result));
      }

      if (+customerId === +oldCustomerId) return;

      try {
        const currentClient = await organizationService.fetchCustomersDetail();
        if (+customerId === +currentClient?.id) this.setCurrentClient(currentClient);
        this.getCurrentClientErrors = 0;
      } catch {
        localStorage.setItem('customerId', null);
        localStorage.setItem('customerType', null);
        this.setCurrentClient(null);

        if (this.getCurrentClientErrors >= 3) return;
        this.autoSetCustomer(null).finally(() => {
          if (this.$route.path.indexOf('/report/') !== -1) this.$router.go();
        });

        this.getCurrentClientErrors += 1;
      }
    },
    async onClickBurger() {
      const lastState = this.openedMenus.mainNav;

      this.setItem({
        item: 'anyMenuIsOpen',
        value: false,
      });
      await this.$nextTick();

      if (lastState) {
        this.openedMenus.mainNav = false;
      } else {
        this.openedMenus.mainNav = true;

        this.setItem({
          item: 'anyMenuIsOpen',
          value: true,
        });
        this.openedMenus.rightMenu = null;
      }
    },
    async onClickRightMenuSwitcher(type) {
      const lastState = this.openedMenus.rightMenu;
      this.setItem({
        item: 'anyMenuIsOpen',
        value: false,
      });
      await this.$nextTick();

      setTimeout(() => { // it's hack for new job v-overlay
        if (!type || lastState === type) {
          this.openedMenus.rightMenu = null;
        } else {
          this.setItem({
            item: 'anyMenuIsOpen',
            value: true,
          });
          this.openedMenus.rightMenu = type;
        }
        this.openedMenus.mainNav = false;
      }, 0)
    },
    onClickCloseMenu() {
      this.setItem({
        item: 'anyMenuIsOpen',
        value: false,
      });
      this.openedMenus.rightMenu = null;
    },
    async onChangeAuthUser() {
      const tokens = await authService.checkTokens();

      if (tokens) {
        const user = await this.fetchCurrentUser({ id: tokens?.decoded?.user_id || tokens?.decoded?.id });
        if (!user) this.logOut();
        else {
          this.getAllFavorites();
        }
      } else {
        if (this.beforeLogOut) this.beforeLogOut();
        authService.logOut();
        this.setCurrentUser(null);
        const pathPages = ['/sign-in', '/sign-up', '/recovery', '/confirm-registration', '/change-password', '/suggest', '/unsubscribe', '/plugin-comeback', '/plugin-install'];
        if (!pathPages.some(path => window.location.pathname.indexOf(path) !== -1)) this.$router.push({ name: 'SignIn' }).catch(() => {});
      }
    },
    async onImportUpload(event, data) {
      event.isLoading = true;
      try {
        await importService.create({ data });
        event.showSuccessMessage({
          message: 'Данные успешно импортированы',
          title: 'Успешно',
        });
      } catch (err) {
        if (typeof err?.response?.data !== 'object') {
          event.ln_showError(err?.response?.data);
          return
        }
        event.ln_handleError(err);
      } finally {
        event.isLoading = false;
      }
    },
    onClickLogOut() {
      this.logOut();
      this.$notify({
        type: 'success',
        title: 'Вы вышли из системы',
      });
    },
    logOut() {
      if (this.beforeLogOut) this.beforeLogOut();
      authService.logOut();
      this.setCurrentUser(null);
      if (!['SignIn', 'SignUp', 'ChangePassword', 'RecoveryPassword', 'ConfirmRegistration', 'SuggestToken'].includes(this.$route.name)) {
        window.location.href = '/sign-in';
        localStorage.removeItem('currentPage');
      }
    },
    getAllFavorites() {
      const currentUser = localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')) : null;
      if (currentUser === null) return;
      const keys = ['SellerFavorites', 'BrandFavorites', 'ProductFavorites', 'CategoryFavorites'];
      Promise.all(keys.map((key) => accountsService.fetchUiSettings(key))).then((response) => {
        response.forEach((item, idx) => {
          if (!keys[idx]) return;
          const favItems = Array.isArray(item) ? item : [];
          localStorage.setItem(keys[idx], JSON.stringify(favItems));
        });
      });
    },
    instructionSkipToOrganization() {
      const unwatch = this.$watch(
          () => this.$route,
          (val, oldVal) => {
            if (oldVal?.name === 'ClientsDetailsPayment' && val?.name === 'ClientsDetailsPayment') {
              this.$refs.tutorial.currentIndex = 21;
              unwatch();
            }
          },
      )
    },
    async beforeNextStepNeedChangeClient() {
      // если текущая организация - демо
      if (this.currentClient?.type_str_id === 'outter_demo') {
        await this.$router.push({
          name: 'ClientsDetail',
          params: {
            clientId: this.customerId,
          }
        }).catch(() => {}).finally(() => this.instructionSkipToOrganization());

        return;
      }

      NProgress.start();

      this.customers = await organizationService.fetchCustomers();
      const { id = null } = (this.customers || [])?.find(({ type_str_id }) => type_str_id === 'outter_demo') || {};
      // Если демо организация вообще у юзера имеется
      if (id !== null) {
        await this.$router.push({
          name: 'ClientsDetail',
          params: {
            clientId: id,
          }
        }).catch(() => {}).finally(() => {
          NProgress.done();
          this.instructionSkipToOrganization();
        });
        return;
      }

      // Иначе редиректим на ту, что выбрана или же на любую первую попавщуюся из списка
      await this.$router.push({
        name: 'ClientsDetail',
        params: {
          clientId: this.customerId || (this.customers && this.customers[0]?.id),
        }
      }).catch(() => {}).finally(() => {
        NProgress.done();
        this.instructionSkipToOrganization();
      });
      return;
    },
    onUpdateCustomersList() {
      this.customers = organizationService.fetchCustomers();
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.routes = {
        from, to,
      };
    })
  },
};
</script>

<style lang="sass">
@import "styles/_vue3-instruction.sass"
@import "styles/materialdesignicons.min.css"

.wrapper
  display: flex
  overflow-x: hidden
  width: 100%

.app
  &__progress
    position: fixed
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)

  &__main
    flex: none
    margin-left: auto
    margin-top: 3.75rem
    padding-top: 0px !important
    //padding-bottom: 1rem !important
    margin-bottom: 4.5rem
    //background-color: var(--v-plain-background)
    width: 100%
    //height: calc(100vh - 3.75rem)
    //&.-dark
      //background-color: var(--v-background-color) !important
    &.menu-is-open
      width: calc(100% - 185px) // 185 - это отступ с открытым меню. фикс ширина у него 250px, но с вычетом v-navigation-drawer - 65px
    @media (max-width: 1264px)
      margin-left: 0 !important
      margin-right: 0 !important
      width: 100%
    &--with-aside
      padding-left: 320px !important

#jsd-widget
  z-index: 5 !important
  color-scheme: initial
</style>

