export default (mailingService) => ({
  namespaced: true,
  state: {
    tgBotAvailable: !!import.meta.env.VITE_APP_MAILING_BOT,
    mailingItems: [],
  },
  mutations: {
    setMailingItems(state, data) {
      state.mailingItems = data;
    },
  },
  actions: {
    async fetchMailingList({ state, commit, dispatch }, userEmail) {
      if (!userEmail) return;

      mailingService.fetch({ path: `/bylogin/${userEmail}` })
        .then((result) => {
          commit('setMailingItems', result || []);
        })
        .catch((err) => {
          console.error('SUBSCRIBE MODULE ERROR, fetchMailingList:', err);
          throw err?.response?.data?.errors?.errorText || err.message;
        })
    },
    async toggleActive({ state, commit, dispatch, getters }, id) {
      const userEmail = getters.getMailingByClientId?.(id)?.email

      const newMailingItems = state.mailingItems?.reduce?.((prev, current) => {
        if (+current.client_id === +id) {
          current.is_active = +!current.is_active
        }

        return prev.concat(current)
      }, [])

      try {
        await mailingService.update({ id: '', data: newMailingItems })

        const result = await dispatch('fetchMailingList', userEmail)
        commit('setMailingItems', result)
      } catch (err) {
        console.error('SUBSCRIBE MODULE ERROR, toggleActive:', err);
        throw err?.response?.data?.errors?.errorText || err.message;
      }
    }
  },
  getters: {
    getMailingItems(state) {
      return state.mailingItems;
    },
    getMailingByClientId: (state) => (id) => {
      return state.mailingItems?.find?.(({ client_id }) => +client_id === +id) || null;
    },
  }
});
