import CommonService from 'fairstatcore/services/Common';

export default class OrganizationService extends CommonService {
  async createCustomers(data) {
    try {
      const res = await this.client.post('/clients', data)
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async fetchCustomersDetail() {
    try {
      const res = await this.client.get('/client');
      return res.data
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async editCustomers(data) {
    try {
      const res = await this.client.patch(`/clients`, data)
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async fetchCustomers() {
    try {
      const res = await this.client.get('/accounts/clients');
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async fetchCustomersInfo() {
    try {
      const res = await this.client.get('/clients/info');
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async addUsersToCustomers(data) {
    try {
      const res = await this.client.post(`/users`, data);
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async fetchCustomersUserDetail(userId) {
    try {
      const { data } = await this.client.get(`/users/${userId}`);
      return data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async editCustomersUser(userId, data) {
    try {
      const res = await this.client.patch(`/users/${userId}`, data);
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async removeCustomersUser(userId) {
    try {
      const res = await this.client.delete(`/users/${userId}`);
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async fetchCustomerUserGrants(userId) {
    try {
      const { data } = await this.client.get(`/grants/${userId}`);
      return data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async confirmInvitedUser(uid) {
    try {
      const res = await this.client.post(`/confirm/add-user`, {
        uid
      });
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async fetchOperationUser(userId) {
    try {
      const res = await this.client.get(`/grants/${userId}`);
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async changeOperationUser(userId, data) {
    try {
      const res = await this.client.patch(`/grants/${userId}`, data);
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async fetchRequisites() {
    try {
      const res = await this.client.get('/client/req');
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async editRequisites(data) {
    try {
      const res = await this.client.patch(`/client/req`, data)
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async currentTariff() {
    try {
      const res = await this.client.get('/client/tarif');
      return res.data;
    } catch (err) {
      console.error('AccountsService error:', err);
      throw err;
    }
  }

  async changeTariff(tariffId) {
    try {
      const res = await this.client.patch(`/tarifs/${tariffId}`)
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async payment(data) {
    try {
      const res = await this.client.post(`/client/pay`, data)
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }

  async requestClosingDocuments(data) {
    try {
      const res = await this.client.post('/bil/close', data)
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }
  async requestAnInvoice(data) {
    try {
      const res = await this.client.post('/bil/invoice', data)
      return res.data;
    } catch (err) {
      console.error('OrganizationService error:', err);
      throw err;
    }
  }
}
