import CommonService from 'fairstatcore/services/Common';

export default class BrandsService extends CommonService {
  async fetchDetail(id) {
    try {
      const res = await this.client.get(`/${id}`);
      return res.data;
    } catch (err) {
      console.error('Brands error:', err);
      throw err;
    }
  }
}
