<template lang="pug">
.auth.pt-6.pb-6.pt-md-8.pb-md-8.pr-4.pl-4.pr-md-15.pl-md-15.mx-auto(
  :class="{ dark: $vuetify?.theme?.current?.dark }"
)
  a.auth__logo.d-flex.align-center(href="https://fairstat.ru")
    InlineSvg(:src="$getSrc('fairstatcore/assets/logo.svg')")
    v-chip(label v-if="!isProd" size="small" color="red").ml-2 Тест
      v-tooltip(activator="parent" location="bottom" text="Вы находитесь на Тестовой среде")
  .auth__row
    .auth__wrap.d-flex.flex-column
      router-view

    .auth__promo.d-flex.flex-column.pt-6.pl-6.pr-6.pt-lg-11.pl-lg-11.pr-lg-11.ma-auto(v-if="$vuetify.display.mdAndUp")
      h2.auth__promo-title.text-h5.text-lg-h4.font-weight-bold.mb-3.mb-lg-6
        | Инструмент аналитики
        br
        | для маркетплейсов
      p.auth__promo-description.text-body-2.text-lg-body-1.mb-7.mb-lg-10.
        Получите доступ к нашему инструменту аналитики: точные данные о конверсиях, заказах, остатках и продажах на маркетплейсах. Удобный дашборд, ABC анализ, отчеты по каналам продаж на 4 ведущих маркетплейсах, для оптимизации стратегии и эффективного управления продажами.
      .auth__promo-picture.mt-auto
        picture
          source(srcset="/src/pages/Auth/assets/promo-report-image.webp", type="image/webp")
          img(src="/src/pages/Auth/assets/promo-report-image.jpg", alt="Демо отчет по дням в FairStat.")
</template>

<script>
import InlineSvg from "vue-inline-svg";

export default {
  components: { InlineSvg },
  computed: {
    isProd() {
      return import.meta.env.MODE === 'production';
    },
  },
  mounted() {
    const routes = ['/sign-up', '/sign-in', '/confirm-registration', '/recovery', '/change-password', '/suggest', '/unsubscribe'];
    const jsdWidget = document.querySelector('#jsd-widget');
    if (!jsdWidget) return;
    this.$watch(
      () => this.$route.path,
      (val) => {
        if (jsdWidget.style.display !== 'none' && routes.includes(val)) {
          jsdWidget.style.display = 'none';
        } else if (jsdWidget.style.display === 'none' && !routes.includes(val)) {
          jsdWidget.style.display = null;
        }
      },
      { immediate: true },
    )
  }
};
</script>

<style lang="sass" scoped>
@import 'vuetify/styles/settings/_variables'
$pfx: "auth"

.#{$pfx}
  background-color: var(--v-gray-100)
  max-width: 1920px
  width: 100%
  @media #{map-get($display-breakpoints, 'md-and-up')}
    min-height: 790px
    height: 100%

  &__row
    width: 100%
    height: 100%
    @media #{map-get($display-breakpoints, 'md-and-up')}
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 30px
  &__wrap
    position: relative
    min-height: calc(100vh - 24px - 24px - 26px) // 24px padding-top/bottom; 26px - height logo
    height: 100%
    @media #{map-get($display-breakpoints, 'md-and-up')}
      min-height: calc(100vh - 32px - 32px - 26px) // 32px padding-top/bottom; 26px - height logo
  &__logo
    top: 0
    left: 0
    opacity: 1
    transition: opacity 0.3s
    &:hover
      opacity: .9
    svg
      pointer-events: none
      height: 17px
      fill: rgb(var(--v-theme-primary))


  &__promo
    overflow: hidden
    background-color: rgb(var(--v-theme-primary))
    border-radius: 24px 24px 0px 24px
    max-width: 620px
    //margin-top: auto
    //margin-bottom: auto
    //margin-left: auto
    color: var(--v-body-color-invert)
    max-height: 775px
    height: 100%

    &-picture
      overflow: hidden
      border-radius: 12px 12px 0 0
      background-color: var(--v-plain-background)
      box-shadow: 0px 24px 44px rgba(0, 0, 0, 0.25)
      width: 100%
      img,
      picture
        width: 100%
  &.dark
    background-color: var(--v-background-color)
    .#{$pfx}
      &__promo
        color: var(--v-color-body)
</style>
