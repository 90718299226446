import CommonService from 'fairstatcore/services/Common';

export default class ProductsService extends CommonService {
  async fetchInfo() {
    try {
      const res = await this.client.get('/info');
      return res.data;
    } catch (err) {
      console.error('Products error:', err);
      throw err;
    }
  }

  async fetchDetail(id) {
    try {
      const res = await this.client.get(`/${id}`);
      return res.data;
    } catch (err) {
      console.error('Products error:', err);
      throw err;
    }
  }

  async fetchSalesByDays(id, days) {
    try {
      const res = await this.client.get(`/${id}/salesByDays?p_days=${days}`);
      return res.data;
    } catch (err) {
      console.error('Products error:', err);
      throw err;
    }
  }
}
