import CommonService from 'fairstatcore/services/Common';

export default class ApiKeysService extends CommonService {
  async fetchFormatKeys() {
    try {
      const res = await this.client.get('/mp');
      return res.data;
    } catch (err) {
      console.error('ApiKeysService error:', err);
      throw err;
    }
  }

  async addFormatKeys(data) {
    try {
      const res = await this.client.post('/keys', data);
      return res.data;
    } catch (err) {
      console.error('ApiKeysService error:', err);
      throw err;
    }
  }

  async fetchDetail(id = null) {
    try {
      const res = await this.client.get(`/keys/${id}`);
      return res.data;
    } catch (err) {
      console.error('ApiKeysService error:', err);
      throw err;
    }
  }

  async remove(id = null) {
    try {
      const res = await this.client.delete(`/keys/${id}`);
      return res.data;
    } catch (err) {
      console.error('ApiKeysService error:', err);
      throw err;
    }
  }

  async fetchScheme(mpId = null, saleId = null) {
    try {
      const res = await this.client.get(`/mp/${mpId}/sale/${saleId}`);
      return res.data;
    } catch (err) {
      console.error('ApiKeysService error:', err);
      throw err;
    }
  }
}
