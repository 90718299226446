<template>
  <div aria-label="scope"></div>
</template>

<script>
import { initializeApp } from "firebase/app";
// import { getAnalytics, setUserId, logEvent, setUserProperties } from "firebase/analytics";
import { initializeAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, updatePassword } from "firebase/auth";
import events from "fairstatcore/eventBus";

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID
};

export default {
  name: 'FirebaseAnalytics',
  data() {
    return {
      isAvailableAnalytics: import.meta.env.NODE_ENV === "production",
      initializeApp: null,
      analytics: null,
    };
  },
  mounted() {
    if (!this.isAvailableAnalytics) return console.info('%c[Firebase Analytics]: Аналитика на тестовой среде отключена.', 'background-color: black; color: yellow;');
    this.initializeApp = initializeApp(firebaseConfig);
    this.analytics = getAnalytics(this.initializeApp);

    events.$on('init', this.init);
    events.$on('logEvent', this.logEvent);
    events.$on('signIn', this.signIn);
    events.$on('signUp', this.signUp);
    events.$on('logOut', this.logOut);
  },
  methods: {
    init() {
      const authDataString = sessionStorage.getItem('authData') || localStorage.getItem('authData')
      const authData = authDataString ? JSON.parse(authDataString) : null;
      if (authData !== null) {
        const currentUserString = localStorage.getItem('currentUser');
        const currentUser = currentUserString ? JSON.parse(currentUserString) : null;
        setUserProperties(this.analytics, {
          clientId: localStorage.getItem('customerId') || null,
          clientType: localStorage.getItem('customerType') || null,
          fio: currentUser?.fio,
          login: currentUser?.login,
        });
        setUserId(this.analytics, authData.userId);
      }
    },
    logEvent(e) {
      const event = e.event || '',
          params = e.params || {};

      if (!event) return console.error('[Firebase Analytics/logEvent]: Не передан ключ события.');
      this.init();
      logEvent(this.analytics, event, params);
    },
    signIn(e) {
      const email = e.email || '';
      const password = e.password || '';

      const auth = initializeAuth(this.initializeApp);
      return signInWithEmailAndPassword(auth, email, password)
    },
    signUp(e) {
      const email = e.email || '';
      const password = e.password || '';

      const auth = initializeAuth(this.initializeApp);
      return createUserWithEmailAndPassword(auth, email, password)
    },
    logOut() {
      const auth = initializeAuth(this.initializeApp);
      signOut(auth);
    },
    changePassword(e) {
      const password = e.password || '';
      const auth = initializeAuth(this.initializeApp);
      const user = auth.currentUser;
      updatePassword(user, password)
    }
  },
}
</script>
