export default (reportsService) => ({
  namespaced: true,
  state: {
    enumsMarketplaces: [],
    enumsSales: [],
  },
  mutations: {
    setMarketplaces(state, data) {
      state.enumsMarketplaces = data;
    },
    setSales(state, data) {
      state.enumsSales = data;
    },
  },
  actions: {
    async fetchMarketplaces({ commit }) {
      try {
        const result = await reportsService.fetchMarketplaces();
        commit('setMarketplaces', result);
      } catch (err) {
        console.error('REPORTS MODULE ERROR, fetchMarketplaces:', err);
        throw err;
      }
    },
    async fetchSales({ commit }) {
      try {
        const result = await reportsService.fetchSales();
        commit('setSales', result);
      } catch (err) {
        console.error('REPORTS MODULE ERROR, fetchSales:', err);
        throw err;
      }
    },
  },
  getters: {
    getMarketplaces(state) {
      return state.enumsMarketplaces;
    },
    getSales(state) {
      return state.enumsSales;
    },
  },
});
