import AnalyticsComp from './Analytics.vue';
import eventBus from "fairstatcore/eventBus";

const Firebase = {
  install(Vue, args = {}) {
    if (this.installed) {
      return
    }

    this.installed = true
    this.params = args

    Vue.component(args.componentName || 'FirebaseAnalytics', AnalyticsComp)

    const analytics = (params = {}) => {
      eventBus.$emit('init', params)
    }

    analytics.logEvent = function (params) {
      eventBus.$emit('logEvent', params)
    }

    analytics.signIn = function (params) {
      eventBus.$emit('signIn', params)
    }

    analytics.signUp = function (params) {
      eventBus.$emit('signUp', params)
    }

    analytics.logOut = function (params) {
      eventBus.$emit('logOut', params)
    }

    analytics.changePassword = function (params) {
      eventBus.$emit('changePass', params);
    }

    const name = args.name || 'analytics'

    Vue.config.globalProperties['$' + name] = analytics
    Vue[name] = analytics
  }
}

export default Firebase
