/**
 * Отчет
 */

export default [
  {
    path: "/report",
    component: () => import("@/pages/Reports/Index.vue"),
    name: "Report",
    redirect: {name: "ReportSells"},
    meta: {
      title: 'Отчеты',
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=ext`,
      checkAvailableWithTariff: ['IN_WB', 'IN_OZ'],
    },
    children: [
      {
        path: "sale",
        component: () => import("@/pages/Reports/Sells.vue"),
        name: "ReportSells",
        meta: {title: 'Отчет о заказах'},
      },
      {
        path: "weekly",
        component: () => import("@/pages/Reports/SellsWeekly.vue"),
        name: "ReportWeekly",
        meta: {title: 'Отчет по недельно'},
      },
      {
        path: "dynamics",
        component: () => import("@/pages/Reports/ReportWithDynamics.vue"),
        name: "ReportWithDynamics",
        meta: {title: 'Отчет с динамикой'},
      },
      {
        path: "by-days",
        component: () => import("@/pages/Reports/ReportByDays.vue"),
        name: "ReportByDays",
        meta: {title: 'Отчет по дням'},
      },
      {
        path: "order-creation-time",
        component: () => import("@/pages/Reports/ReportOrderCreationTime.vue"),
        name: "ReportOrderCreationTime",
        meta: {title: 'Время создания заказов'},
      },
      {
        path: "sales-funnel",
        component: () => import("@/pages/Reports/SalesFunnel/Index.vue"),
        name: "ReportSalesFunnel",
        meta: {title: 'Воронка продаж'},
      }
    ],
  },
  // Отчет ABC анализ
  {
    path: "/report-abc",
    component: () => import("@/pages/Reports/Index.vue"),
    name: "ReportABC",
    redirect: {name: "ReportABCAnalysis"},
    meta: {
      title: 'Отчеты',
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=ext`,
      checkAvailableWithTariff: ['IN_WB', 'IN_OZ'],
    },
    children: [
      {
        path: "abc",
        component: () => import("@/pages/Reports/ReportABCAnalysis.vue"),
        name: "ReportABCAnalysis",
        meta: {title: "Отчет по ABC-анализу"},
      },
      {
        path: "compare-goods",
        component: () => import("@/pages/Reports/ReportABCCompareGoods.vue"),
        name: "ReportABCCompareGoods",
        meta: {title: "Отчет по сравнению товаров"},
      },
    ],
  },
]
