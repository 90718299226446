export default (productsService) => ({
  namespaced: true,
  state: {
    detail: null,
    salesByDays: null,
  },
  mutations: {
    setDetail(state, data) {
      state.detail = data;
    },
    setSalesByDays(state, data) {
      state.salesByDays = data;
    },
  },
  actions: {
    async fetchProduct({ commit }, sku) {
      try {
        const response = await productsService.fetchDetail(sku);
        commit('setDetail', response);
        return response;
      } catch (err) {
        console.error('PRODUCT MODULE ERROR, fetchProduct:', err);
        throw err;
      }
    },
    async fetchSalesByDays({ commit }, { id, days }) {
      try {
        const response = await productsService.fetchSalesByDays(id, days);
        commit('setSalesByDays', response);
        return response;
      } catch (err) {
        console.error('PRODUCT MODULE ERROR, fetchSalesByDays:', err);
        throw err;
      }
    },
  },
  getters: {
    getProduct(state) {
      return state.detail;
    },
    getSalesByDays(state) {
      return state.salesByDays;
    },
  },
});
