// import authService from 'fairstatcore/services/Auth';
import moment from "moment";

export default (organizationService) => ({
  namespaced: true,
  state: {
    grants: null,
    clientUser: null,
    customerId: localStorage.getItem('customerId'),
    customerType: localStorage.getItem('customerType'),
    currentClient: null,
    currentTariff: null,
  },
  mutations: {
    setClientUser(state, data) {
      state.clientUser = data;
    },
    setGrants(state, grants) {
      state.grants = grants;
    },
    setCustomerId(state, id) {
      state.customerId = id;
    },
    setCustomerType(state, id) {
      state.setCustomerType = id;
    },
    setCurrentClient(state, data) {
      const [start, end] = [
        +new Date(new Date().toISOString()),
        +new Date(data?.expire_at),
      ];
      const tariffDaysLeft = moment(end).diff(moment(start), 'days');
      const tariffHoursLeft = moment(end).diff(moment(start), 'hours');
      const tariffMinutesLeft = moment(end).diff(moment(start), 'minutes');

      state.currentClient = {
        ...data,
        tariffHasExpired: tariffDaysLeft <= 0 && tariffHoursLeft <= 0 && tariffMinutesLeft <= 0,
      };
      if (data) {
        localStorage.setItem('customerId', data?.id);
        localStorage.setItem('customerType', data?.type_str_id);
      }
    },
    setCurrentTariff(state, tariff) {
      state.currentTariff = tariff;
      localStorage.setItem('currentTariff', JSON.stringify(tariff));
    },
  },
  actions: {
    async fetchClientUser({ state, commit, dispatch }, userId) {
      if (!userId) return;
      organizationService.fetchCustomersUserDetail(userId).then((result) => {
        // Если пользователь заблокирован
        if (result && result.status === 2) {
          localStorage.setItem('customerId', null);
          localStorage.setItem('customerType', null);
          commit('setCustomerId', null);
          commit('setCustomerType', null);
          commit('setClientUser', null);
          return;
        }
        commit('setClientUser', result);
      }).catch((err) => {
        console.error('ORGANIZATION MODULE ERROR, fetchClientUser:', err);
        if (err.response.data.errors.errorText.indexOf('Аккаунт клиента не найден')) {
          localStorage.setItem('customerId', null);
          localStorage.setItem('customerType', null);
          commit('setCustomerId', null);
          commit('setCustomerType', null);

          // this.$router.go(this.$router.currentRoute)
        }
        throw err;
      }).finally(async () => {
        await dispatch('loadCurrentGrants', userId);
      });
    },
    async loadCurrentGrants({ commit }, userId) {
      try {
        const { result = [] } = await organizationService.fetchOperationUser(userId);
        commit('setGrants', result);
      } catch (err) {
        console.error('ORGANIZATION MODULE ERROR, loadCurrentGrants:', err);
        throw err;
      }
    },
    async loadCurrentTariff({ state }) {
      if (!state.customerId) return null;
      try {
        return await organizationService.currentTariff();
      } catch (err) {
        console.error('ORGANIZATION MODULE ERROR, loadCurrentTariff:', err);
        throw err;
      }
    },
  },
  getters: {
    getCustomerId(state) {
      return state.customerId
    },
    getCustomerType(state) {
      return state.customerType
    },
    getCurrentClient(state) {
      return state.currentClient;
    },
    getCurrentTariff(state) {
      return state.currentTariff;
    },
  }
});
