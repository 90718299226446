import { createApp } from 'vue'
import { createStore } from './store'
import { createRouter } from './router'
import { createMetaManager } from 'vue-meta'
import createHooks from "./router/hooks";
import getSrc from "fairstatcore/utils/getSrc";
import Core from "fairstatcore";
import FairStatUi from "fairstatui";
import { createVuetify } from "fairstatui/composables"
import "fairstatui/dist/fairstatui.css"
import FirebaseAnalytics from "./plugins/FirebaseAnalytics/index.js";
import App from "./App.vue";
import 'vuetify/styles'

const store = createStore();
const router = createRouter();
createHooks(router, store);

const app = createApp({
  ...App,
});

app.config.globalProperties.$getSrc = getSrc;

app.use(createVuetify).use(store).use(router).use(Core).use(FirebaseAnalytics).use(FairStatUi).use(createMetaManager()).mount('#app');
