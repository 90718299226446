export default [
  {
    path: "/price-monitoring",
    component: () => import("@/pages/PriceMonitoring/Index.vue"),
    name: "PriceMonitoring",
    meta: {
      title: 'Мониторинг цен',
      checkAvailableService: `${import.meta.env.VITE_APP_APFS_URL}/ping?db=int`,
      checkAvailableWithTariff: ['OUT_WB', 'OUT_OZ'], //'IN_WB', 'IN_OZ'
    },
  }
]
