<template lang="pug">
v-dialog(:model-value="dialogValue" max-width="610" v-if="$vuetify.display.mdAndUp && countDaysSinceRelease <= 2" persistent @update:modelValue="handleCloseDialog")
  template(v-slot:default="{ isActive }")
    v-card(rounded="lg").pt-0
      v-card-title.d-flex.justify-space-between.align-center.mt-2
        .text-h5.text-medium-emphasis.ps-2
          | Примечания к выпуску
          a(href="https://t.me/FAIRSTAT", target="_blank")
            v-icon(icon="custom:Telegram" size="x-small").ml-2
            v-tooltip(activator="parent" text="Наш Telegram канал." location="bottom" )

        span.d-flex.text-subtitle-1.align-center.text-medium-emphasis.cursor-help
          v-icon(size="small").mr-2 mdi-clock-outline
          | {{ $filters.toOnlyDate(releaseDate) }}
          v-tooltip(activator="parent" text="Дата выпуска обновлений" location="bottom")
      v-divider.mt-2.mb-2

      .position-relative(style="min-height:70px;")
        .position-absolute.mx-auto.d-flex.align-center.justify-space-between.w-100.pr-5.pl-5(
          style="z-index:1;top:0;bottom:0;"
        )
          v-btn(
            icon="mdi-chevron-left"
            elevation="1"
            :disabled="carouselValue <= 0"
            color="white"
            @click="carouselValue = Math.max(carouselValue - 1, 0)"
          )
          v-btn(
            icon="mdi-chevron-right"
            elevation="1"
            :disabled="carouselValue >= releaseItems.length - 1"
            color="white"
            @click="carouselValue = Math.min(carouselValue + 1, releaseItems.length - 1)"
          )
        v-carousel(hide-delimiters :show-arrows="false" :height="null" v-if="isShowCarousel" v-model="carouselValue").pr-4.pl-4
          v-carousel-item(v-for="(release, idx) in releaseItems" :src="release.picture" :key="`carouselItem${idx}`")

      v-card-text(v-for="(release, idx) in releaseItems" :key="`contentItem${idx}`" v-show="idx === carouselValue").pl-4.pr-4
        v-card-title(v-if="release.title").d-inline-block.pa-0.mb-3
          v-icon(v-if="release.icon" :icon="release.icon" size="small").mr-2.d-inline-flex
          h6.text-h6(v-html="release.title").d-inline

        p.text-medium-emphasis(v-html="release.description")

        .mt-5.mt-md-8.d-flex.align-center.justify-space-between.w-100
          component(
            v-if="release.action"
            :is="release.action.component"
            v-bind="{ ...release.action.props, ...release.action.listeners }"
          ).mr-2
          v-btn(
            tonal
            prepend-icon="mdi-close"
            color="primary"
            elevation="0"
            @click="isActive.value = false"
          ).text-none Закрыть
</template>

<script>
import moment from "moment";
import { themesManager } from "fairstatui/composables";
import { accountsService } from "fairstatcore/services";

export default {
  data() {
    return {
      dialogValue: false,
      carouselValue: 0,
      releaseDate: new Date('2024-06-02').toISOString(),
      releaseItems: [
        {
          icon: 'mdi-chart-box',
          title: 'Новый отчет.<br>Время создания заказа.',
          description: 'Отчет показывает количество созданных заказов с разбивкой по часам для Заказчика за выбранный период времени и по выбранном маркетплейсам.',
          picture: this.$getSrc('@/assets/releases/2024-06-02/new-report.png'),
          action: {
            component: 'v-btn',
            props: {
              text: 'Перейти к отчету',
              variant: 'outlined',
              appendIcon: 'mdi-chevron-right',
              class: 'text-none',
            },
            listeners: {
              onClick: () => this.$router.push({ name: 'ReportOrderCreationTime' }),
            },
          },
        },
        {
          title: '🔥 Темная и светлая тема!',
          description: null,
          picture: this.$getSrc('@/assets/releases/2024-06-02/dark-theme.png'),
          action: {
            component: 'v-btn',
            props: {
              text: 'Переключить тему',
              variant: 'outlined',
              appendIcon: 'mdi-swap-horizontal',
              class: 'text-none',
            },
            listeners: {
              onClick: () => themesManager.change(!this.$vuetify?.theme?.current?.dark ? 'dark' : 'light'),
            },
          },
        },
        {
          icon: 'mdi-robot-confused',
          title: 'Проверка на робота. <br> Google Recaptcha 2.',
          description: 'Подключили дополнительно 2 версию Google Recaptcha.<br>Работает как альтернативный вариант 3 версии.',
          picture: this.$getSrc('@/assets/releases/2024-06-02/recaptcha2.png'),
        },
      ]
    };
  },
  async created() {
    try {
      const { viewed = false } = await accountsService.fetchUiSettings(`releaseNotes${this.releaseDate.slice(0, 10)}`);
      this.dialogValue = !viewed;
    } catch (err) {
      console.error(err);
    }
  },
  computed: {
    /**
     * Получаем кол-во дней с момента релиза
     * @returns {number}
     */
    countDaysSinceRelease() {
      const [start, end] = [
        +new Date(`${this.releaseDate.slice(0, 10)}T00:00:00.000Z`), // день релиза
        +new Date(`${new Date().toISOString().slice(0, 10)}T00:00:00.000Z`), // сегоднешняя дата
      ];
      return moment(end).diff(moment(start), 'days');
    },
    isShowCarousel() {
      return !this.releaseItems.every(({ picture }) => !picture);
    }
  },
  methods: {
    handleCloseDialog(val) {
      if (!val) {
        this.dialogValue = val;
        accountsService.updateUiSettings(`releaseNotes${this.releaseDate.slice(0, 10)}`, { viewed: true });
      }
    },
  },
}
</script>
