import axios from "axios";
import NProgress from "nprogress";
import authService from 'fairstatcore/services/Auth';
import { accountsService } from "fairstatcore/services";

NProgress.configure({ parent: '#app' });
let lastRequestServiceTime = null;

const checkAvailableReport = async (to, from, next, store) => {
  const currentUser = localStorage.getItem('currentUser') !== null ? JSON.parse(localStorage.getItem('currentUser')) : null;

  store.commit('organization/setCustomerId', null);
  store.commit('organization/setCustomerType', null);

  if (currentUser && currentUser.status === 4 && to.name === 'ConfirmRegistration') {
    next();
  } else {
    if (currentUser && currentUser.status === 4 && to.name !== 'UnavailableWithTariff') {
      next({ name: 'UnavailableWithTariff' })
    } else {
      const { customerId = null, customerType = null } = authService.getCustomerId();
      store.commit('organization/setCustomerId', customerId)
      store.commit('organization/setCustomerType', customerType)

      // Проверяем доступ раздела в зависимости от тарифа
      const tariffCode = to.meta.checkAvailableWithTariff || (to.matched[0] && to.matched[0].meta.checkAvailableWithTariff); // Код тарифа
      if (tariffCode?.length) {
        const currentTariff = localStorage.getItem('currentTariff') !== null ? JSON.parse(localStorage.getItem('currentTariff')) : null; // Текущий тариф пользователя
        if (!currentTariff) {
          next();
        } else {
          try {
            if (currentTariff && currentTariff.client_id != customerId) {
              next()
            } else {
              const modules = await accountsService.fetch({ path: `/tarifs/${currentTariff.tarif_id}/modules` }); // Все модули

              // Если есть доступ по модулю или если демо орг, то разрешаем.
              const currentMarketplace = store.state.currentMarketplace || (localStorage.getItem('currentMarketplace') || 'WB');
              const findModulesByKeys = (modules || []).filter(({ code }) => (tariffCode || []).find((it) => it.toLowerCase() === code.toLowerCase()));
              if (
                (
                  tariffCode.some((code) => ['OUT_', 'IN_'].some((key) => code.indexOf(key) !== -1)) ? // Если в коде поверки есть начало с OUT_/IN_ то считаем, что проверка производиться на разделы внутренней и внешней аналитики
                    (findModulesByKeys.findIndex(({ code }) => code.toLowerCase().indexOf(currentMarketplace.toLowerCase()) !== -1) !== -1) : // тут проверяем доступ к разделам в разрезе текущего тарифа и его моделй с выбранным маркетплейсом
                    (modules && modules.findIndex(({ code }) => (tariffCode || []).some(key => key.toLowerCase() === code.toLowerCase())) !== -1) // тут проверяем все разделы на доступ в разрезе тарифа не относящиеся к внутренней/внешней аналитики
                )
              ) {
                // Для просмотра отчетов - нужно подтверждение телефона и чтобы тариф был не просрочен.
                if (to?.href?.indexOf('/report') !== -1) {
                  if (currentUser && currentUser.status !== 4 && !currentUser?.phone_confirmed) return next({ name: 'ProfileView', hash: '#confirm-phone-dialog' })
                  if (Boolean(store?.state?.organization?.currentClient?.tariffHasExpired)) return next({ name: 'UnavailableWithTariff' })
                  next()
                } else {
                  next();
                }
              } else {
                next({name: 'UnavailableWithTariff'})
              }
            }
          } catch (err) {
            customerId && customerId !== 'null' ? next({ name: 'UnavailableWithTariff'}) : next();
          }
        }
      } else {
        next();
      }
    }
  }
}

export default function (router, store) {
  const pathPages = ['SignIn', 'SignUp', 'RecoveryPassword', 'ConfirmRegistration', 'ChangePassword', 'SuggestToken', 'Unsubscribe', 'PluginComeback', 'PluginInstall'];
  const pagesNotVerify = ['UnavailableWithPing', 'ConfirmRegistration', 'SuggestToken', 'Unsubscribe', 'PluginComeback', 'PluginInstall'];
  const initial = {}; // Необходимо записывать состояние и удалять при выходе со страницы, т.к. нет возможности игнорировать изменения одного и того же маршрута (params/query). из-за этого срабатывает несколько проверок.

  router.afterEach((to, from) => {
    if (
      to?.matched?.[0]?.path?.indexOf?.(':mp') !== -1
      && (to?.params?.mp !== localStorage.getItem('currentMarketplace') || !to?.params?.mp)
    ) {
      to.params.mp = to?.params?.mp || localStorage.getItem('currentMarketplace')
    }
    // if (from?.name !== undefined && to?.name !== from?.name) {
    //   /**
    //    * Сбрасываем все фильтры перед переходом
    //    */
    //   store.commit('Filters/setSorts', null);
    //   store.commit('Filters/setFilters', null);
    // }
  })

  router.beforeResolve(async (to, from, next) => {

    /**
     * Проверка на доступ к сайту (заглушка сайт на реконструкции)
     */
    const userDataString = sessionStorage.getItem('currentUser') || localStorage.getItem('currentUser')
    const userData = userDataString ? JSON.parse(userDataString) : null;

    // Если не авторизован и Если прошло больше 10 секунд от последнего запроса, то пропускаем.
    if (userData && (+new Date() - lastRequestServiceTime) > 10000) {
      lastRequestServiceTime = +new Date();
      await accountsService.fetch({ path: '/service', timeout: 60000 }).then((service) => {
        // Если сервисная отбивка включена
        if (service) {
          if (to.name !== 'UnavailableWithMaintenance') {
            // Любая страница кроме заглушки и у юзера есть разрешение то пропускаем
            if (userData?.fs_type === 6 || userData?.fs_type === undefined) {
              // next();
            } else {
              // Иначе редиректим на заглушкуредиректим на
              next({ name: 'UnavailableWithMaintenance' })
            }
          } else if (to.name === 'UnavailableWithMaintenance' && userData?.fs_type === 6 || userData?.fs_type === undefined) {
            // Тут редирект на дашборд в любом случае, если пользователь заходит с правами к просмотру на заглушку
            next({ name: 'Dashboard' })
          }
        } else if (!service && to.name === 'UnavailableWithMaintenance') {
          next({ name: 'Dashboard' })
        }
      }).catch((error) => {
        if (error.code === 'ECONNABORTED') return next({ name: 'Dashboard' });
        if (to.name !== 'UnavailableWithMaintenance') {
          next({ name: 'UnavailableWithMaintenance' })
        }
      })
    }

    /**
     * Проверка на доступ к сайту (заглушка сайт на реконструкции)
     */
    if (!initial[to.name] && (to.meta.checkAvailableService || (to.matched[0] && to.matched[0].meta.checkAvailableService))) {
      try {
        await axios.get(to.meta.checkAvailableService || (to.matched[0] && to.matched[0].meta.checkAvailableService));
        initial[to.name] = true;
        await checkAvailableReport(to, from, next, store);
      } catch (error) {
        if (error.code === 'ECONNABORTED') return next({ name: 'Dashboard' });
        next({ name: 'UnavailableWithPing', query: { to: to.name } });
      }
    } else {
      await checkAvailableReport(to, from, next, store);
    }
  });

  router.beforeEach(async (to, from, next) => {
    const toName = to.name || to.matched?.[0]?.name;

    // Отслеживаем выход со станицы
    if (toName !== from.name) {
      NProgress.start()
      initial[toName] = false;
    }

    const authData = sessionStorage.getItem('authData') || localStorage.getItem('authData');
    if (authData) {
      if (pathPages.includes(toName) && !pagesNotVerify.includes(toName)) next(from);
      else {
        next();
      }
    } else if (!pathPages.includes(toName) && !pagesNotVerify.includes(toName)) {
      localStorage.setItem('currentPage', `${window.location.pathname}${window.location.search}`);
      next({ name: 'SignIn' });
    } else {
      next();
    }
  });

  router.afterEach(() => {
    NProgress.done();
  })
}
